import Vue from 'vue'
import App from './App.vue'
import store from './store/index'

//router
import VueRouter from 'vue-router'
//Meta
import Meta from 'vue-meta';

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import AOS from 'aos'
import VueObserveVisibility from 'vue-observe-visibility'
import Toast from "vue-toastification";

import 'aos/dist/aos.css'

import "./filters"

import VueLazyload from 'vue-lazyload'

Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(VueAxios, axios)

Vue.use(VueLazyload)
Vue.use(VueObserveVisibility)
Vue.use(Toast);


function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}

Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: '/images/loading.gif',
  attempt: 1
})

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: lazyLoad('Home') },
    { path: '/about', component: lazyLoad('About')},
    { path: '/team', component: lazyLoad('Team')},
    { path: '/thankyou', component: lazyLoad('ThankYou')},
    { path: '/contact', component: lazyLoad('Contact')},
    { path: '/team', component: lazyLoad('Team')},
    { path: '/press', component: lazyLoad('Press')},
    { path: '/properties/:category?', component: lazyLoad('Properties')},
    { path: '/property/:slug', component: lazyLoad('Property')},
    { path: "*", component: lazyLoad('PageNotFound') },
  ],
  scrollBehavior: function(to) {
    if (to.hash) {
        return { selector: to.hash }
    } else {
        return { x: 0, y: 0 }
    }
  },
})
new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted(){
    AOS.init({
      duration: 1200,
    })
  },
}).$mount('#app')
