<template>
    <div class="socialLinks">
        <a v-for="item in socialMediaItems" class="socialIcon" :key="item.icon" v-bind:href="item.url" target="_blank">
            <img :src="`/images/icon/social-${item.icon}.svg`" alt="" srcset="">
        </a>
    </div>
</template>

<script>
export default {
    name: "FooterSocialMedia",
    data(){
        return{
            socialMediaItems: [
                {
                    icon: 'instagram',
                    url:"https://www.instagram.com/",
                },
                {
                    icon: 'linkedin',
                    url:" https://www.linkedin.com/",
                },
                {
                    icon: 'facebook',
                    url:"https://www.facebook.com/",
                },   
            ]
        }
    }

}
</script>

<style lang="scss" scoped>
.socialLinks{
    display: flex;
    justify-content: center;
}
.socialIcon{
    margin: 0 0.75rem;
    &:hover{
        opacity: 0.85;
    }
    img{
        width: 46px;
    }
}
</style>