<template>
    <nav role="navigation" v-bind:class="{ topMenuShow: topMenuShow}">
        <div class="desktop_menu">
          <div class="logos-container">
            <router-link to="/">
              <img class="logo" src="/images/logo-wp.svg" alt="SW">
            </router-link>
            <router-link class="notShow" to="/">
              <img class="logo" src="/images/logo-tbhe.svg" alt="TBHE">
            </router-link>
          </div>
          <div class="menuLinks">
            <router-link v-for="item in menuItems" :key="item.label" :to="item.link" @click.native="linkClick">
              {{ item.label }}
            </router-link>
          </div>
        </div>
        <div class="mobile_menu">
          <router-link to="/">
            <img class="logo" src="/images/logo-wp.svg" alt="SW">
          </router-link>
          <transition name="fade">
            <div id="menu" v-if="menuShow"> 
                <ul>
                  <li v-for="item in menuItems" :key="item.label" >
                    <router-link :to="item.link" @click.native="linkClick">
                      {{ item.label }}
                    </router-link>
                  </li>
                </ul>
                <div class="social-media-footer">
                  <p>
                    Follow
                  </p>
                  <FooterSocialMedia />
                </div>
            </div>
          </transition>
          <div id="menuToggle" >
              <input type="checkbox" v-model="menuShow"/>
              <span></span>
              <span></span>
              <span></span>
          </div>
        </div>
    </nav>
    
</template>

<script>
import FooterSocialMedia from "../Footer/FooterSocialMedia";

export default {
    name: 'Menu',
    components: {FooterSocialMedia},
    data(){
        return{ 
          menuItems: [
              {
                  label: "About",
                  link: "/about"
              },
              {
                  label: "Team",
                  link: "/team"
              },
              {
                  label: "Listings",
                  link: "/properties"
              },
              {
                  label: "Press",
                  link: "/press"
              },
              {
                  label: "Contact",
                  link: "/contact"
              },
          ],
          menuShow: false,
          topMenuShow: false,
      }
    },
    methods: {
      linkClick(){
        this.menuShow = false;
      },
      handleScroll(){
        if (window.scrollY > 170){
          this.topMenuShow = true;
        }else if(window.scrollY < 20){
          this.topMenuShow = false;
        }
      },
    },
    watch:{
      $route (){
        this.menuShow = false;
      }
    },
    created: function(){
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed(){
      window.removeEventListener('scroll', this.handleScroll);
    },
  }
</script>

<style lang="scss" scoped>
nav {
  position: relative;
  width: 100%;
  top: 0;
  z-index: 10;
  background: #000;
  a {
    &:visited{
      color: #fff;
    }
    color: #fff;
    text-decoration: none;
    &:hover{
      opacity: 0.8;
    }
  }
  .menuLinks{
    padding-right: 28px;
    color: #fff;
    text-transform: uppercase;
    text-align: right;
    a{
      font-size: 19px;
      line-height: 23px;
      letter-spacing: 0.1em;
      padding-right: 2rem;
      opacity: 1;
      &:hover{
        opacity: 0.6;
      }
    }
  }
}

.desktop_menu{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 126px;
  .logos-container{
    display: flex;
    margin-left: 50px;
    .logo{
      height: 50px;
      margin-right: 20px;
    }
  }
  .site-name{
    display: none;
  }
}

.mobile_menu{
  display: none;
  background: black;
}

.topMenuShow{
  background: black;
  position: fixed;

  .desktop_menu{
    height: 84px;
    .notShow{
      display: none;
    }
  }
}


#menuToggle
{
  position: absolute;
  top: 22px;
  right: 20px;
  z-index: 20;
  -webkit-user-select: none;
  user-select: none;
  & input {
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    left: -3px;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    
    -webkit-touch-callout: none;
  }
  & span {
    margin-top: 5px;
    display: block;
    width: 30px;
    height: 3px;
    margin-bottom: 7px;
    position: relative;
    background: #fff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:nth-last-child(2){
      transform-origin: 0% 100%;
    }
  }
  input:checked ~ span{
    opacity: 0.8;
    transform: rotate(45deg) translate(-8px, -15px);
    background: #fff;
    width: 30px;
    &:nth-last-child(3){
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    &:nth-last-child(2){
      transform: rotate(-45deg) translate(-4px, 11px);
      width: 30px;
    }
  }
}

#menu
{
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  display: block;
  background-color:rgba(0, 0, 0, 0.9);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  z-index: 10;
  ul{
    list-style-type: none;
    margin: 0 auto;
    margin-top: 120px;
    padding-inline-start: unset;
  }
  ul>li {
    color:#fff;
    font-size: 45px;
    line-height: 45px;
    font-weight: 300;
    margin: 25px 0;
    text-transform: uppercase;
    &:hover{
      opacity: 0.85;
    }
  }
  
}

@media (max-width: $tablet) {
  .desktop_menu{
    display: none;
  }
  .mobile_menu{
    display: flex;
    background: #000;
    height: 74px;
    align-items: center;
    .logo{
      height: 50px;
      margin-left: 20px;
    }
      #menu ul{
          padding-bottom: 1.5rem;
      }
  }
  nav{
    background: black;
    position: fixed;
  }
  .social-media-footer{
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
  p{
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.1em;
      color: #8D7632;
  }
}
</style>
