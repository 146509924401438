import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'es6-promise/auto'

Vue.use(Vuex)
Vue.use(VueAxios, axios)


const TWO_HOURS = 2 * 60 * 60 * 1000

const state = {
    loaded: false,
    pressLoaded: false,
    expiryDate: new Date,
    properties: [],
    press: [],
    isMobile: false,
    isTablet: false,
    isDesktop: false,
}

// getters
const getters = {
    checkExpiryDate: (state) => {
        return ((new Date) - state.expiryDate) > TWO_HOURS;
    },
    getLoaded: (state) => {
        return state.properties.length > 0;
    },
    getPressLoaded: (state) => {
        return state.press.length > 0;
    },
    properties: (state) => {
        return state.properties;
    },
    press: (state) => {
        return state.press;
    },
    isMobile: (state) => {
        return state.isMobile;
    },
    isTablet: (state) => {
        return state.isTablet;
    },
    isDesktop: (state) => {
        return state.isDesktop;
    },
}

// actions
const actions = {
    getProperties (context){
        context.commit('getProperties')
    },
    getPress(context){
        context.commit('getPress')
    },
    updateCurrentTime(context){
        context.commit('updateCurrentTime')
    },
    updateProperties(context){
        context.commit('getProperties');
        context.commit('getPress');
        context.commit('updateCurrentTime')
    },
    updateDesktop(context){
        context.commit('setDesktop');
    },
    updateTablet(context){
        context.commit('setTablet');
    },
    updateMobile(context){
        context.commit('setMobile');
    },
}

// mutations
const mutations = {
    getProperties (state){
        axios.get('https://strapi.uptowncreative.io/SWLA-Properties?_sort=Price:DESC')
        .then((response) => {
            let temp = response.data
            temp = temp.sort((first, second) => {
                if (first.Status === 'available' && second.Status !== 'available'){
                    return -1;
                } else if (first.Status !== 'available' && second.Status === 'available'){
                    return 1;
                }else if ((first.Status === '' || first.Status === null) && (second.Status !== '' || second.Status !== null)){
                    return -1;
                } else if ((first.Status !== '' || first.Status !== null) && (second.Status === '' || second.Status === null)){
                    return 1;
                } else if (first.Status === 'inEscrow' && second.Status !== 'inEscrow'){
                    return -1;
                } else if (first.Status !== 'inEscrow' && second.Status === 'inEscrow'){
                    return 1;
                } else if (first.Status === 'sold' && second.Status !== 'sold'){
                    return -1;
                } else {
                    return Number(first.Price) < Number(second.Price) ? 1 : -1;
                }
            })
            state.properties = temp
        })
        state.loaded = true;
    },
    getPress (state){
        axios.get('https://strapi.uptowncreative.io/SWLA-Presses?_sort=Order:ASC')
        .then((response) => {
            state.press = response.data
            state.pressLoaded = true;
        })
    },
    setDesktop (state){
        state.isDesktop = true;
    },
    setTablet (state){
        state.isTablet = true;
    },
    setMobile (state){
        state.isMobile = true;
    },
    updateCurrentTime (state){
        state.expiryDate = new Date;
    }
}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})