<template>
  <div id="app">
    <Header />
    <router-view></router-view>
    <Footer> </Footer>
  </div>
</template>

<script>
import lazyLoadComponent from './utils/lazy-load-component';
import SkeletonBox from './components/SkeletonBox';
import Header from './components/Header'

const defaultOptions = {
  loading: SkeletonBox,
  loadingData: {
    props: {
      width: `100%`,
      height: `20em`,
    },
  },
};

export default {
  name: 'app',
  components: {
    Header,
    Footer: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./components/Footer.vue`),
    }),
  },
  mounted:function(){
    this.$store.dispatch('updateProperties');
    if(window.innerWidth > 1079 ){
      this.$store.dispatch('updateDesktop');
    } else if (window.innerWidth > 641){
      this.$store.dispatch('updateTablet');
    } else {
      this.$store.dispatch('updateMobile');
    }
  },
}

import 'vueperslides/dist/vueperslides.css';
import "vue-toastification/dist/index.css";
</script>

<style lang="scss">
@import url('https://use.typekit.net/kpw7xig.css');
html, body{
  position: relative;
  font-family: scandia-web, sans-serif;
  font-weight: 300;
  margin:0;
  padding:0;
  height: 100%;
  min-height: 100vh;
  width:100%;
}
body>div {overflow-x:hidden;}

h1{
  font-size: 100px;
  line-height: 100px;
  font-weight: 300;
  overflow: hidden;
}

h2{
  font-size: 70px;
  line-height: 100px;
  font-weight: 300;
}

h3{
  font-weight: 400;
  font-size: 29px;
  line-height: 31px;
  font-weight: 300;
}

h5{
  font-weight: 275;
  font-size: 26px;
  line-height: 26px;
  color: #000000;
}

p, label{
  display: block;
  font-family: poppins, sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
}

.btn{
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  letter-spacing: 0.2em;

  color: #000000;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #E09F20, #E7C98F);
  padding: 16px 22px;
  img{
    display: inline;
    margin-left: 5px;
    margin-bottom: 2px;
  }
}

.overlay-container{
  position: relative;
  display: block;
}

.overlay{
  transition: 1s ease;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  a{
    color:#fff;
    display: flex;
    align-items: center;
  }
}

.overlay-container:hover img {
  opacity: 0.3;
}

.overlay-container:hover .overlay {
  opacity: 0.8;
}

.arrow{
  opacity: 1 !important;
}


hr{
  background-image: linear-gradient(to left, #E09F20, #E7C98F);
  
  border: 0;
  height: 1px;
  margin: 70px;
}

.btn-submit{
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  letter-spacing: 0.2em;
  background: #000;
  color: #fff;
  padding: 18px 24px;
  text-transform: uppercase;
  border: none;
  img{
    display: inline;
    margin-left: 5px;
    margin-bottom: 2px;
  }
  &:hover{
    cursor: pointer;
  }
}

.tag{
  font-family: poppins, sans-serif;
  font-weight: 275;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  background: #000;
  color: #fff;
  padding: 5px 20px;
}

.coming-soon{
  margin: 0 auto;
  height: 50vh;
  text-align: center;
}

*{
  box-sizing: border-box;
}

.vue-simple-spinner-text{
  letter-spacing: 3px;
  font-size: 1rem !important;
  color: #000 !important;
}

.mobile-only{
  display: none;
}


@media (max-width: $tablet) {
  h1{
    font-size: 62px;
    line-height: 62px;
  }
  h2{
    font-size: 50px;
    line-height: 50px;
    font-weight: 300;
  }
}

@media (max-width: $mobile) {
  .desktop-only{
    display: none;
  }
  .mobile-only{
    display: block;
  }
}
</style>